<template>
  <div class="d-flex flex-column">
    <jumbotron :src="destination.mainImage" class="text--white">
      <v-container class="fill-height pa-0 ma-0 white--text">
        <v-row class="fill-height" justify="start" align="end">
          <v-col :cols="12">
            <h1
              :class="{
                'display-2': isOnPC,
                'display-1': !isOnPC,
                'px-4': isOnPC,
                'px-2': !isOnPC
              }"
              style="text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.7)"
            >{{ destination.title ? destination.title : destination.name }}</h1>
          </v-col>
        </v-row>
      </v-container>
    </jumbotron>
    <v-container>
      <v-row>
        <v-col cols="12">
          <article v-if="isOnPC" v-html="destination.article" />
          <article v-else v-html="mobileHtml" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-tabs v-model="detailTab">
              <v-tab>Location</v-tab>
              <v-tab>Reviews</v-tab>
              <v-tab>You May Also Like</v-tab>
            </v-tabs>
            <v-tabs-items class="fill-height" v-model="detailTab">
              <v-tab-item>
                <v-responsive :aspect-ratio="isOnPC ? 22 / 9 : 1">
                  <leaflet-map
                    :zoom="isOnPC ? undefined : 12"
                    :point="destinationCoordinates"
                    v-show="!navbarIsOpen"
                  >
                    <v-card>
                      <v-card-title v-show="!isOnPC" class="text-no-wrap ma-0 pb-0" v-text="destination.address.regency" />
                      <v-card-text v-show="!isOnPC" class="py-0 text--secondary" v-if="destination.address.detailAddress || destination.address.district">
                        <span v-if="destination.address.detailAddress" v-text="`${destination.address.detailAddress}, `" />
                        <span v-text="destination.address.district" />
                      </v-card-text>
                      <v-list-item class="pl-0" three-line v-show="isOnPC">
                        <v-list-item-content>
                          <v-card-title class="text-no-wrap ma-0 pb-1" v-text="destination.address.regency" />
                          <v-card-text class="py-0 text--secondary" v-if="destination.address.detailAddress || destination.address.district">
                            <span v-if="destination.address.detailAddress" v-text="`${destination.address.detailAddress}, `" />
                            <span v-text="destination.address.district" />
                          </v-card-text>
                        </v-list-item-content>
                      </v-list-item>
                      <v-card-actions>
                        <v-btn :small="!isOnPC" :href="gmapsUrlParameter" text color="primary" target="__blank">
                          Directions
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </leaflet-map>
                </v-responsive>
              </v-tab-item>

              <v-tab-item>
                <review-panel :destination-id="`${this.destinationId}`"/>
              </v-tab-item>

              <v-tab-item>
                <under-construction />
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Jumbotron from '../components/Jumbotron'
import firebase from '../plugins/firebase'
import LeafletMap from '../components/DestinationViewer/Map'
import UnderConstruction from '../views/UnderConstruction'
import ReviewPanel from '../components/DestinationViewer/DestinationReviews/ReviewPanel'

export default {
  components: {
    jumbotron: Jumbotron,
    'leaflet-map': LeafletMap,
    'under-construction': UnderConstruction,
    'review-panel': ReviewPanel
  },

  props: {
    destinationId: String,
    url: String
  },

  data () {
    return ({
      destination: {},
      loading: true,
      detailTab: 0
    })
  },
  computed: {
    navbarIsOpen () {
      return this.$store.state.navbarIsOpen
    },
    isOnPC () { return this.$vuetify.breakpoint.mdAndUp },
    mobileHtml () {
      const source = this.$data.destination.article
      const result = source.replace(
        /<img\s+.*?\/?>/gmi,
        x => x.replace(/style=".*?"/gmi, 'style="width: 100%"')
      )
      return result
    },
    destinationCoordinates () {
      if (!this.$data.destination.coordinates) return null
      return ({
        lat: this.$data.destination.coordinates.latitude,
        lng: this.$data.destination.coordinates.longitude
      })
    },
    destinationCompositeAddress () {
      var o = ''
      if (this.$data.destination.address.detailAddress) o = `${this.$data.destination.detailAddress}, `
      if (this.$data.destination.address.district) o = `${o}${this.$data.destination.address.district}, `
      o = `${o}${this.$data.destination.address.regency}`

      return o
    },
    gmapsUrlParameter () {
      const gmapsUrl = new URL('https://www.google.com/maps/dir/?api=1')
      gmapsUrl.searchParams.append('destination', `${this.$data.destination.name}, ${this.destinationCompositeAddress}`)
      if (this.$data.destination.gMapsPlaceId) gmapsUrl.searchParams.append('destination_place_id', this.$data.destination.gMapsPlaceId)
      return gmapsUrl.toString()
    }
  },

  async created () {
    this.$data.loading = true
    this.$data.destination = await this.getDestinationData()
    if (this.$data.destination === null) return this.$router.go('/NotFound')
    this.$data.loading = false
  },

  watch: {
    destination: function (v) {
      document.title = `${v.title ? v.title : v.name} - AmborJo`
    }
  },

  methods: {
    getDestinationData () {
      const db = firebase.firestore()

      return new Promise((resolve, reject) => {
        db.collection('destinations/').doc(this.destinationId).get().then(doc => {
          if (!doc.exists) { resolve(null) } else {
            resolve(doc.data())
          }
        }).catch(e => reject(e))
      })
    }
  }
}
</script>
