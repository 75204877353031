<template>
  <v-dialog v-model="dialogOpen">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on">{{ myOwnReview ? 'Edit Your Review' : 'Write a Review' }}</v-btn>
    </template>
    <v-sheet class="pa-4 text-center">
      <h3 class="headline">Write a Review</h3>
      <v-rating x-large v-model="rating" hover />
      <v-textarea
        label="Describe your experience."
        hint="Please be considerate. Your name and photo are visible to the public."
        outlined
        :counter="maxReviewLength"
        v-model="review"
        class="mx-6 mt-3"
      />
      <div class="d-flex justify-end">
        <v-btn class="mx-2" @click="close">Cancel</v-btn>
        <v-btn class="mx-2" :loading="submitting" :disabled="!canSubmit" color="primary" @click="submitReview">Submit</v-btn>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script>
import firebase from '../../../plugins/firebase'

export default {
  name: 'ReviewDialog',

  props: {
    'destination-id': {
      type: String,
      required: true
    },
    'max-review-length': {
      type: Number,
      default () { return 140 }
    },
    'my-own-review': Object
  },

  data () {
    return ({
      rating: this.myOwnReview ? this.myOwnReview.rating : 0,
      review: this.myOwnReview ? this.myOwnReview.review : '',
      submitting: false,
      dialogOpen: false
    })
  },

  computed: {
    canSubmit () {
      return this.$data.rating && this.$data.review.length && !this.$data.submitting && this.$data.review.length <= this.maxReviewLength
    }
  },

  methods: {
    close () {
      this.$data.review = this.myOwnReview ? this.myOwnReview.review : ''
      this.$data.rating = this.myOwnReview ? this.myOwnReview.rating : 0
      this.$data.dialogOpen = false
    },

    submitReview () {
      this.$data.submitting = true
      var reviewDocRef = firebase.firestore().collection(`/destinations/${this.destinationId}/reviews`)
      if (this.myOwnReview) { reviewDocRef = reviewDocRef.doc(this.myOwnReview.id) } else {
        reviewDocRef = reviewDocRef.doc()
      }
      reviewDocRef.set({
        rating: this.$data.rating,
        review: this.$data.review,
        modified: firebase.firestore.FieldValue.serverTimestamp(),
        authorUid: this.$store.getters['auth/user'].uid
      }).then(() => {
        this.$store.commit('toast/setNotification', 'Thank you for your review.')
        this.$emit('change')
        this.close()
      }).catch(() => {
        this.$store.commit('toast/setError', 'Review submission failed.')
      }).finally(() => {
        this.$data.submitting = false
      })
    }
  }
}
</script>
