<template>
  <l-map id="myMap" ref="map"
    @update:center="pan"
    :style="{ width: width, height: height }"
    :center="point ? { lat: point.lat + 0.0025, lng: point.lng } : center"
    :zoom="zoom"
    :options="{
      zoomControl: false,
      attributionControl: false
    }"
  >
    <l-tile-layer :url="url" />
    <l-marker :lat-lng="point" />
    <l-control position="topleft"><slot /></l-control>
    <l-control-zoom position="bottomright" />
  </l-map>
</template>

<script>
import { LMap, LTileLayer, LMarker, LControl, LControlZoom } from 'vue2-leaflet'
// import { latLng } from 'leaflet'
import { Icon } from 'leaflet'
import 'leaflet/dist/leaflet.css'

// this part resolve an issue where the markers would not appear
delete Icon.Default.prototype._getIconUrl

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

export default {
  components: {
    'l-map': LMap,
    'l-tile-layer': LTileLayer,
    'l-marker': LMarker,
    'l-control': LControl,
    'l-control-zoom': LControlZoom
  },
  props: {
    point: Object,
    center: {
      type: Object,
      default: () => ({ lat: 1.493056, lng: 124.841261 })
    },
    zoom: {
      type: Number,
      default: () => 16
    },
    height: {
      default: () => '100%'
    },
    width: {
      default: () => '100%'
    },
    url: {
      default: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    }
  },

  computed: {
    isOnPC () {
      return this.$vuetify.breakpoints
    },
    hasInfoWindow () {
      return !!this.$slots.default
    }
  },

  methods: {
    pan (e) {
      // console.log(e)
    }
  }
}
</script>

<style>
.leaflet-container {
  font: 16px/1.5 Roboto, sans-serif !important;
}
</style>
