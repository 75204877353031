<template>
  <v-row>
    <v-col cols="1">
      <v-avatar>
        <v-img :src="review.authorPhotoUrl" />
      </v-avatar>
    </v-col>
    <v-col cols="11">
      <h4 class="subheader" v-text="review.authorName" />
      <div class="">
        <span class="mr-2"><v-icon v-for="n in review.rating" :key="n">mdi-star</v-icon><v-icon v-for="n in 5 - review.rating" :key="n">mdi-star-outline</v-icon></span>
        <span class="font-weight-light font-italic" v-text="userFriendlyDate" />
      </div>
      <p v-text="review.review" />
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'

export default {
  name: 'ReviewEntry',
  props: {
    review: {
      type: Object,
      required: true
    }
  },

  computed: {
    userFriendlyDate () {
      return moment(this.review.modified.toDate()).format('L')
    }
  },

  created () {
    moment.locale(window.navigator.userLanguage || window.navigator.language)
  }
}
</script>
