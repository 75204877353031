<template>
  <v-container fluid class="fill-height ma-0">
    <v-row align="center" class="pa-0 ma-0">
      <v-col cols="12" xs="12" sm="12" md="4" class="text-center">
        <strong class="display-2 d-block">{{ stars }}</strong>
        <div><v-rating :value="stars" half-increments readonly /></div>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="8">
        <div class="d-flex justify-end">
          <review-dialog v-if="canReview" @change="refreshReviews()" :destination-id="destinationId" :my-own-review="myOwnReview" />
          <div v-else>You have to sign-in before reviewing.</div>
        </div>
        <div class="text-center" v-if="loading"><v-progress-circular indeterminate /></div>
        <div v-else
          class="fill-height pa-0 ma-0"
          fluid
        >
          <v-container class="pa-0 ma-0" v-if="reviews.length">
            <review-entry
              v-for="(review, n) in reviews"
              :key="`${n}${JSON.stringify(review)}`"
              :review="review"
            />
            <!-- <div>{{ reviews[0].review }}</div> -->
          </v-container>
          <v-row v-else align="center" justify="center">No review to display.</v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import VueApexCharts from 'vue-apexcharts'
import firebase from '../../../plugins/firebase'
import ReviewDialog from './ReviewDialog'
import ReviewEntry from './ReviewEntry'

export default {
  name: 'ReviewPanel',

  components: {
    // 'review-breakdown': VueApexCharts,
    'review-dialog': ReviewDialog,
    'review-entry': ReviewEntry
  },

  computed: {
    canReview () {
      return !!this.$store.getters['auth/user']
    }
  },

  props: {
    rating: {
      type: Number,
      validator (v) {
        return v >= 0 && v <= 5
      }
    },
    destinationId: {
      type: String,
      required: true
    }
  },

  data () {
    return ({
      stars: this.rating,
      loading: false,
      reviews: [],
      myOwnReview: null
    })
  },

  async created () {
    await this.refreshReviews()
  },

  methods: {
    async refreshReviews () {
      this.$data.loading = true
      this.$data.stars = await this.fetchRating()
      this.$data.reviews = await this.fetchReviews()
      this.$data.myOwnReview = this.$data.reviews.find(value => value.authorUid === this.$store.getters['auth/user'].uid)
      this.$data.loading = false
    },

    fetchReviews () {
      return new Promise((resolve, reject) => {
        firebase.firestore().collection(`/destinations/${this.destinationId}/reviews`).get()
          .then(reviewCollection => {
            if (reviewCollection.docs.length === 0) resolve([])
            var o = reviewCollection.docs.map(doc => Object.assign(doc.data(), { id: doc.id }))
            resolve(o)
          })
          .catch(e => { reject(e) })
      })
    },

    fetchRating () {
      return new Promise((resolve, reject) => {
        firebase.firestore().doc(`/destinations/${this.destinationId}`).get()
          .then(doc => { resolve(doc.data().rating) })
          .catch(e => { reject(e) })
      })
    }
  }
}
</script>
